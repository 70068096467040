import { Avatar, Box, Button, MenuItem, Typography } from '@mui/material';
import { FC, isValidElement, ReactNode } from 'react';
import { useAuth } from '../../../../application/context/AuthContext';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useModeratorLanguage } from '../../../../application/context/ModeratorContext';
import { ModeratorLanguage } from '../../../../application/types/ModeratorLanguage.enum';
interface UserCardSubMenuProps {
  handleClose: Function;
}

const UserCardSubMenu: FC<UserCardSubMenuProps> = ({ handleClose }) => {
  const { logOutAction, isLogoutLoading } = useAuth();
  const { t } = useTranslation();
  const { moderatorPreferredLanguage, changePreferredLanguage } = useModeratorLanguage();

  const handleLanguageChange = (event: SelectChangeEvent, childSelected: ReactNode): void => {
    if (!isValidElement(childSelected)) {
      return;
    }

    changePreferredLanguage(childSelected.props.value);
    handleClose();
  };

  return (
    <>
      <Box sx={{ p: theme.spacing(1, 2), width: '252px' }}>
        <Typography sx={{ margin: theme.spacing(1, 0) }} variant='body1'>
          {t('SIDEBAR.LANGUAGESELECTION.LABEL')}
        </Typography>
        <Select
          sx={{ height: '40px' }}
          fullWidth={true}
          value={moderatorPreferredLanguage}
          renderValue={(value) => (
            <Typography variant='body1'>
              {value === ModeratorLanguage.FR ? t('LANGUAGE.FR') : t('LANGUAGE.EN')}
            </Typography>
          )}
          onChange={(e, childSelected) => handleLanguageChange(e, childSelected)}
        >
          <MenuItem value={ModeratorLanguage.FR}>
            <Avatar
              sx={{ width: 24, height: 24, marginRight: theme.spacing(1) }}
              src={`https://flagcdn.com/w40/fr.png`}
            />
            <div style={{ paddingTop: '3px' }}>{t('LANGUAGE.FR')}</div>
          </MenuItem>
          <MenuItem value={ModeratorLanguage.EN}>
            <Avatar
              sx={{ width: 24, height: 24, marginRight: theme.spacing(1) }}
              src={`https://flagcdn.com/w40/gb.png`}
            />
            <div style={{ paddingTop: '3px' }}>{t('LANGUAGE.EN')}</div>
          </MenuItem>
        </Select>
      </Box>

      <Box sx={{ p: theme.spacing(1, 2), width: '252px' }}>
        <Button
          disabled={isLogoutLoading}
          fullWidth={true}
          variant='outlined'
          color='error'
          onClick={() => logOutAction()}
        >
          {t('SIDEBAR.LOGOUT')}
        </Button>
      </Box>
    </>
  );
};

export default UserCardSubMenu;
