import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FCWithChildren } from '../../infrastructure/types/global';
import { Channel, ChannelStatus } from '../types/Channel';
import { channelService } from '../services/ChannelService';
import { isDefined, isEmptyString } from '../../utils/type-utils';
import ApiError from '../services/error/ErrorService';
import { ApplicationRoutes } from '../constants/navigation';
import { ModerationOutcome } from '../types/MessageModeration';
import { messageService } from '../services/MessageService';
import { userService } from '../services/UserService';
import { UserBanCount } from '../types/UserBans';
import { Member, MemberRole } from '../types/Member';
import { useSocket } from './SocketContext';
import { MessageDetailsEvent } from '../types/MessageDetailsEvent';
import { useQuery, useMutation } from '@tanstack/react-query';
import { UserUnbanEvent } from '../types/UserUnBanEvent';
import { Message } from '../types/Message';
import { UserBanEvent } from '../types/UserBanEvent';
import { useModeratorLanguage } from './ModeratorContext';
import { ModeratorLanguage } from '../types/ModeratorLanguage.enum';
import { useAuth } from './AuthContext';

interface ChannelContextProps extends Channel {
  inReadonlyMode: boolean;
  inRemoderateMode: boolean;
  loading: boolean;
  sellerBanCount: UserBanCount | undefined;
  buyerBanCount: UserBanCount | undefined;
  lastNewMessageIdSeen: number | null;
  error: string | null;
  onUserDetailsModalOpenClose: (isOpen: boolean) => void;
  editRemoderation: () => void;
  resetLastMessageSeenId: () => void;
  toggleMessageCheck: (messageId: number) => void;
  flagSelectedMessagesForModeration: (ModerationOutcome: ModerationOutcome) => void;
  submitMessageModeration: () => void;
  hasFlaggedAllRequiredMessages: boolean;
  selectedMessages: number[];
  disableTranslation: boolean;
  switchDisableTranslation: () => void;
  messageToDisplay: (messageToDisplay: Message) => string;
  messageCanBeTranslated: (messageToDisplay: Message) => boolean;
}

const ChannelContext = createContext<ChannelContextProps | undefined>(undefined);

export const ChannelProvider: FCWithChildren = ({ children }) => {
  const params = useParams<{ '*': string }>();
  const [providerId, setProviderId] = useState<string>('');
  const [channelId, setChannelId] = useState<number>(0);
  const [messages, setMessages] = useState<Message[]>([]);
  const [members, setMembers] = useState<Member[]>([]);
  const [status, setStatus] = useState<ChannelStatus>(ChannelStatus.UNPROCESSED);
  const [createdAt, setCreatedAt] = useState<string>('');
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [createdById, setCreatedById] = useState<number>(0);
  const [productId, setProductId] = useState<number>(0);
  const [productPath, setProductPath] = useState<string>('');
  const [productPicture, setProductPicture] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [inReadonlyMode, setInReadonlyMode] = useState(true);
  const [inRemoderateMode, setInRemoderateMode] = useState(false);
  const [canEditInRemoderate, setCanEditInRemodereate] = useState(false);
  const [lastNewMessageIdSeen, setLastNewMessageIdSeen] = useState<null | number>(null);
  const [sellerBanCount, setSellerBanCount] = useState<UserBanCount>();
  const [buyerBanCount, setBuyerBanCount] = useState<UserBanCount>();
  const [error, setError] = useState<string | null>(null);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [disableTranslation, setDisableTranslation] = useState(false);
  const [seller, setSeller] = useState<Member>();
  const [buyer, setBuyer] = useState<Member>();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const lastNewMessageIdSeenRef = useRef(lastNewMessageIdSeen);

  const { lastBanningEvent, lastUnbanningEvent, lastMessageEvent } = useSocket();
  const { moderatorPreferredLanguage } = useModeratorLanguage();

  useEffect(() => {
    if (!isDefined(status)) {
      return;
    }
    if (status === ChannelStatus.IN_PROCESSING) {
      setInReadonlyMode(readOnly);
    } else {
      setInReadonlyMode(status !== ChannelStatus.UNPROCESSED && !canEditInRemoderate);
    }
    setInRemoderateMode(status === ChannelStatus.PROCESSED);
  }, [readOnly, status, canEditInRemoderate]);

  useEffect(() => {
    const currentLastNewMessageIdSeen = lastNewMessageIdSeenRef.current;

    const onNewMessage = (messageEvent: MessageDetailsEvent | null): void => {
      if (!isDefined(messages) || !isDefined(messageEvent)) {
        return;
      }

      if (channelId !== messageEvent.channelId) {
        return;
      }

      const hasMessageBeenAlreadyAdded = messages.some((message) => message.id === messageEvent.id);

      if (hasMessageBeenAlreadyAdded) {
        return;
      }

      if (!isDefined(currentLastNewMessageIdSeen)) {
        setLastNewMessageIdSeen(messageEvent.id);
      }

      messages.push({
        ...messageEvent,
        isSelected: false,
        senderId: Number(messageEvent.senderId),
        moderation: {
          required: false,
        },
      });
    };

    onNewMessage(lastMessageEvent);
  }, [channelId, lastMessageEvent, messages]);

  const updateMemberBansCount = useCallback((members: Member[]): void => {
    members.forEach((member) => {
      if (member.role === MemberRole.BUYER) {
        setBuyer(member);
      } else {
        setSeller(member);
      }
    });
  }, []);

  useEffect(() => {
    if (!isDefined(members) || !isDefined(lastBanningEvent)) {
      return;
    }
    const memberToUpdate = members.find((member) => member.vcId === lastBanningEvent.vcId);

    if (isDefined(memberToUpdate)) {
      updateMemberBansCount([memberToUpdate]);
    }
  });

  useEffect(() => {
    if (!isDefined(members)) {
      return;
    }
    updateMemberBansCount(members);
  }, [isUserDetailsModalOpen, members, updateMemberBansCount]);

  const resetLastMessageSeenId = (): void => {
    setLastNewMessageIdSeen(null);
  };

  const switchDisableTranslation = (): void => {
    setDisableTranslation(!disableTranslation);
  };

  const onUserDetailsModalOpenClose = (isOpen: boolean): void => {
    setIsUserDetailsModalOpen(isOpen);
  };

  const { data: buyerBanHistory } = useQuery({
    queryKey: ['banCount', buyer?.id],
    queryFn: () => {
      if (!isDefined(buyer)) {
        return;
      }
      return userService.getUserBanHistory(buyer);
    },
    staleTime: Infinity,
    enabled: isDefined(buyer),
  });

  const { data: sellerBanHistory } = useQuery({
    queryKey: ['banCount', seller?.id],
    queryFn: () => {
      if (!isDefined(seller)) {
        return;
      }
      return userService.getUserBanHistory(seller);
    },
    staleTime: Infinity,
    enabled: isDefined(seller),
  });

  const {
    refetch: channelRefetch,
    data: channelQueryData,
    error: channelQueryError,
    isLoading: channelIsLoading,
  } = useQuery({
    queryKey: ['channel'],
    queryFn: () => {
      const providerIdFromParam = isEmptyString(params['*']) ? undefined : params['*'];
      return channelService.getChannelQuery(providerIdFromParam);
    },
    staleTime: Infinity,
    retry: false,
    enabled: isDefined(params),
  });

  useEffect(() => {
    if (!isDefined(buyerBanHistory)) {
      return;
    }
    setBuyerBanCount(userService.getUserBanCount(buyerBanHistory));
  }, [buyerBanHistory]);

  useEffect(() => {
    if (!isDefined(sellerBanHistory)) {
      return;
    }
    setSellerBanCount(userService.getUserBanCount(sellerBanHistory));
  }, [sellerBanHistory]);

  useEffect(() => {
    setLoading(channelIsLoading);
  }, [channelIsLoading]);

  useEffect(() => {
    if (channelQueryError instanceof ApiError && channelQueryError.status === 404) {
      navigate(ApplicationRoutes.NO_RESULTS);
      return;
    } else if (isDefined(channelQueryError)) {
      setError('Failed to fetch channel');
      return;
    }
    if (!isDefined(channelQueryData)) {
      return;
    }
    const fetchedChannel = channelService.formatChannelFromDto(channelQueryData);
    setChannelId(fetchedChannel.channelId);
    setMessages(fetchedChannel.messages);
    setMembers(fetchedChannel.members);
    setStatus(fetchedChannel.status);
    setCreatedAt(fetchedChannel.createdAt);
    setReadOnly(fetchedChannel.readOnly);
    setProviderId(fetchedChannel.providerId);
    setCreatedById(fetchedChannel.createdById);
    setProductId(fetchedChannel.productId);
    setProviderId(fetchedChannel.providerId);
    setProductPath(fetchedChannel.productPath);
    setProductPicture(fetchedChannel.productPicture);
    setCanEditInRemodereate(false);
    updateMemberBansCount(fetchedChannel.members);
  }, [channelQueryData, channelQueryError, navigate, updateMemberBansCount]);

  useEffect(() => {
    if (auth?.isAuthorized) {
      return;
    }
    channelRefetch();
  }, [channelRefetch, params, auth]);

  const toggleMessageCheck = (messageId: number): void => {
    const updatedMessages = messages.map((message) => {
      if (message.id === messageId) {
        return { ...message, isSelected: !message.isSelected };
      }

      return message;
    });

    setMessages(updatedMessages);
  };

  const handleBanUnbanEvents = useCallback(
    (banEvent: UserUnbanEvent | UserBanEvent | null): void => {
      if (!isDefined(banEvent)) {
        return;
      }
      const memberToUpdate = members.find((member) => member.vcId === banEvent.vcId);

      if (isDefined(memberToUpdate)) {
        updateMemberBansCount([memberToUpdate]);
      }
    },
    [members, updateMemberBansCount],
  );

  useEffect(() => {
    handleBanUnbanEvents(lastBanningEvent);
  }, [handleBanUnbanEvents, lastBanningEvent]);

  useEffect(() => {
    handleBanUnbanEvents(lastUnbanningEvent);
  }, [lastUnbanningEvent, handleBanUnbanEvents]);

  const getSelectedMessages = (): number[] | undefined =>
    messages.filter((message) => message.isSelected).map((message) => message.id);

  const hasFlaggedAllRequiredMessages = (): boolean => {
    if (status === ChannelStatus.PROCESSED) {
      return messages.filter((message) => isDefined(message.moderation.flaggedOutcome)).length !== 0;
    }

    return messages
      .filter((message) => message.moderation.required)
      .every((message) => isDefined(message.moderation.flaggedOutcome));
  };

  const flagSelectedMessagesForModeration = (ModerationOutcome: ModerationOutcome): void => {
    const selectedMessages = getSelectedMessages();

    if (!isDefined(selectedMessages)) {
      return;
    }

    const updatedMessages = messages.map((message) => {
      if (selectedMessages.includes(message.id)) {
        return {
          ...message,
          isSelected: false,
          moderation: { ...message.moderation, flaggedOutcome: ModerationOutcome },
        };
      }

      return message;
    });

    setMessages(updatedMessages);
  };

  const editRemoderation = (): void => {
    if (inRemoderateMode) {
      setCanEditInRemodereate(true);
    }
  };

  const messageToDisplay = (messageToDisplay: Message): string => {
    if (!messageCanBeTranslated(messageToDisplay) || !isDefined(messageToDisplay.translation) || disableTranslation) {
      return messageToDisplay.text;
    }
    if (moderatorPreferredLanguage === ModeratorLanguage.EN) {
      return messageToDisplay.translation.en;
    }
    return messageToDisplay.translation.fr;
  };

  const messageCanBeTranslated = (messageToDisplay: Message): boolean => {
    if (!isDefined(messageToDisplay.translation)) {
      return false;
    }
    if (moderatorPreferredLanguage === ModeratorLanguage.EN) {
      return messageToDisplay.translation.en === '' || !isDefined(messageToDisplay.translation.en) ? false : true;
    }
    return messageToDisplay.translation.fr === '' || !isDefined(messageToDisplay.translation.fr) ? false : true;
  };

  const { mutate: submitMessageModeration } = useMutation({
    mutationFn: async () => {
      const translationLanguage = disableTranslation ? undefined : moderatorPreferredLanguage;
      return messageService.classifyMessages(channelId, messages, translationLanguage);
    },
    onSuccess: () => {
      channelRefetch();
    },
    onError: (error: unknown) => {
      console.error(error);
      const errorMessage = 'Failed to submit message moderation';
      setError(errorMessage);
    },
  });

  return (
    <ChannelContext.Provider
      value={{
        channelId,
        status,
        createdById,
        createdAt,
        readOnly,
        productId,
        productPath,
        productPicture,
        providerId,
        inReadonlyMode,
        inRemoderateMode,
        lastNewMessageIdSeen,
        loading,
        onUserDetailsModalOpenClose,
        error,
        toggleMessageCheck,
        flagSelectedMessagesForModeration,
        submitMessageModeration,
        resetLastMessageSeenId,
        editRemoderation,
        buyerBanCount,
        sellerBanCount,
        hasFlaggedAllRequiredMessages: hasFlaggedAllRequiredMessages(),
        selectedMessages: getSelectedMessages() ?? [],
        messageToDisplay,
        messageCanBeTranslated,
        disableTranslation,
        switchDisableTranslation,
        members,
        messages,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export const useChannel = (): ChannelContextProps => {
  const context = useContext(ChannelContext);
  if (!isDefined(context)) {
    throw new Error('useChannel must be used within a ChannelProvider');
  }
  return context;
};
