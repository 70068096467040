import ApiError from '../../application/services/error/ErrorService';
import { AuthTokens } from '../../application/types/AuthTokens';
import { AuthModeratorDto } from '../../application/types/dto/ModeratorDto';

class PapiClient {
  static headers: HeadersInit;
  // the moderator's email is requested to be passed by BE as temp setup
  // we should be aware of the security implications of this as it opens up possibilities of impersonation
  // this is because the BE currently does not verify the token body against the signature
  private static moderatorEmail: string;

  public static async authoriseForModerators(authTokens: AuthTokens): Promise<AuthModeratorDto> {
    const papiUrl = process.env.REACT_APP_PAPI_URL;

    this.moderatorEmail = authTokens.idToken.email;

    this.headers = {
      Authorization: `Bearer ${authTokens.accessToken}`,
      'Content-Type': 'application/json',
    };

    if (!papiUrl) {
      throw new Error('PAPI_URL is not defined');
    }

    const response = await fetch(`${papiUrl}/moderators`, {
      method: 'POST',
      headers: this.headers,
    });

    if (!response.ok) {
      throw new ApiError(response.statusText, response);
    }

    return response.json();
  }

  public static async get<T>(endpoint: string): Promise<T> {
    const papiUrl = process.env.REACT_APP_PAPI_URL;

    if (!papiUrl) {
      throw new Error('PAPI_URL is not defined');
    }

    const response = await fetch(`${papiUrl}${endpoint}`, {
      method: 'GET',
      headers: this.headers,
    });

    if (!response.ok) {
      const err = new ApiError(response.statusText, response);
      throw err;
    }

    return response.json();
  }

  public static async post<T, U>(endpoint: string, data: U): Promise<T> {
    const papiUrl = process.env.REACT_APP_PAPI_URL;

    if (!papiUrl) {
      throw new Error('PAPI_URL is not defined');
    }

    const response = await fetch(`${papiUrl}${endpoint}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        ...data,
        moderatorEmail: this.moderatorEmail,
      }),
    });

    if (!response.ok) {
      const err = new ApiError(response.statusText, response);
      throw err;
    }

    return response.json();
  }

  public static async postWithNoResponseBody(endpoint: string): Promise<Response> {
    const papiUrl = process.env.REACT_APP_PAPI_URL;

    if (!papiUrl) {
      throw new Error('PAPI_URL is not defined');
    }

    const response = await fetch(`${papiUrl}${endpoint}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        moderatorEmail: this.moderatorEmail,
      }),
    });

    if (!response.ok) {
      const err = new ApiError(response.statusText, response);
      throw err;
    }

    return response;
  }

  public static async put<T, U>(endpoint: string, data: U): Promise<T> {
    const papiUrl = process.env.REACT_APP_PAPI_URL;

    if (!papiUrl) {
      throw new Error('PAPI_URL is not defined');
    }

    const response = await fetch(`${papiUrl}${endpoint}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify({
        ...data,
        moderatorEmail: this.moderatorEmail,
      }),
    });

    if (!response.ok) {
      const err = new ApiError(response.statusText, response);
      throw err;
    }

    return response.json();
  }

  public static async patch<T, U>(endpoint: string, data?: U): Promise<T> {
    const papiUrl = process.env.REACT_APP_PAPI_URL;

    if (!papiUrl) {
      throw new Error('PAPI_URL is not defined');
    }

    const response = await fetch(`${papiUrl}${endpoint}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        ...data,
        moderatorEmail: this.moderatorEmail,
      }),
    });

    if (!response.ok) {
      const err = new ApiError(response.statusText, response);
      throw err;
    }

    return response.json();
  }
}

export default PapiClient;
